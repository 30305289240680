<template lang="html">
  <section class="view-new-small">
    <bannerHead dataTitle="Comunidad UFV"></bannerHead>
    <breacrumbs v-if="getLightEntryBySLug(slug)" dataParent="Comunidad UFV" dataParentLink="/comunidad-ufv"
      :dataTitle="getLightEntryBySLug(slug).category.name"></breacrumbs>
    <div class="container">
      <template v-if="getLightEntryBySLug(slug)">
        <p class="title-line container"> {{ getLightEntryBySLug(slug).category.name }}</p>
        <div class="box-new-small">
          <div class="div-image">
            <img :src="getLightEntryBySLug(slug).image.url" :alt="getLightEntryBySLug(slug).name">
          </div>
          <div class="content-info">
            <p class="date">{{ currentDateTime(getLightEntryBySLug(slug).publish_date) }}</p>
            <p class="title">{{ getLightEntryBySLug(slug).name }}</p>
            <p class="description">{{ getLightEntryBySLug(slug).header }}
            </p>
          </div>
        </div>
      </template>
    </div>
    <div v-if="Object.keys(relatedLightEntries).length != 0">
      <div class="container-recomended">
        <template v-if="Object.keys(relatedLightEntries).length > 4">
          <button @click="$refs.carousel.goToPrev()" class="prevSlide" title="Next Slide"></button>
          <button @click="$refs.carousel.goToNext()" class="nextSlide" title="Prev Slide"></button>
        </template>
        <div class="container">
          <p class="title-line -small" v-if="getLightEntryBySLug(slug)">
            Más {{ getLightEntryBySLug(slug).category.name }}
          </p>
          <agile :options="myOptions" ref="carousel" class="d-flex jcfs aife container"
            v-if="Object.keys(relatedLightEntries).length > 4">
            <template v-for="(entry, key ) in relatedLightEntries" :key="key">
              <router-link v-if="slug != entry.slug" class="link-box-img w-100" :to="{
                name: 'viewNewSmall',
                params: {
                  cat: entry.category.slug,
                  slug: entry.slug,
                }
              }">
                <img :src="entry.image.url" :alt="entry.name">
              </router-link>
            </template>
          </agile>
          <template v-else>
            <div :options="myOptions" ref="carousel" class="d-flex jcfs aife container"
              v-if="Object.keys(relatedLightEntries).length != 0">
              <template v-for="(entry, key ) in relatedLightEntries" :key="key">
                <router-link v-if="slug != entry.slug" class="link-box-img " :to="{
                  name: 'viewNewSmall',
                  params: {
                    cat: entry.category.slug,
                    slug: entry.slug,
                  }
                }">
                  <img :src="entry.image.url" :alt="entry.name">
                </router-link>
              </template>
            </div>
          </template>

        </div>
      </div>
    </div>

  </section>
</template>

<script lang="js">
import bannerHead from '@/components/banner-head.vue'
import breacrumbs from '@/components/breacrumbs.vue'
import moment from 'moment'
import {
  VueAgile
} from "vue-agile";
import { contentStore } from '@/stores/contents'
const store = contentStore();
export default {
  name: 'view-new-small',
  props: ['cat', 'slug'],
  components: {
    agile: VueAgile,
    bannerHead,
    breacrumbs
  },
  setup() {
    return {
      store,
      getLightEntryByCategoryBySLug: store.getLightEntryByCategoryBySLug,
      getLightEntryBySLug: store.getLightEntryBySLug
    }
  },
  mounted() {
    store.loadLightEntries()

  },
  data: () => ({
    myOptions: {
      responsive: [{
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          dots: false,
          navButtons: false,
          centerMode: true,
        },
      }, {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          dots: false,
          navButtons: false,
          centerMode: true,
          unagile: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          dots: true,
          centerMode: true,
          unagile: false,
          navButtons: true,
        },
      }, {
        breakpoint: 0,
        settings: {
          dots: true,
          slidesToShow: 1,
          navButtons: true,
        },
      },
      ],
    },
  }),
  methods: {
    currentDateTime(date) {
      return moment(date).locale('es').format('L')
    },
  },
  computed: {
    relatedLightEntries() {
      return this.getLightEntryByCategoryBySLug(this.cat, this.slug);
    }
  }
}


</script>

<style scoped lang="scss">
.view-new-small {
  .link-box-img {
    width: 25%;
  }
}

@media screen and (max-width:1300px) {
  .prevSlide {
    left: 0%;
  }

  .nextSlide {
    right: 0%
  }
}

@media screen and (max-width:700px) {
  .container-recomended .agile.d-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
